<template>
  <div class="verifier-container" v-if="!loading">
    <div class="header">
      <div class="menu-container" @click="toggleMenu">
        <div id="nav-icon3" class="d-lg-none" :class="{ open: showMenu }">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="title">The Verifier</div>
    </div>
    <div class="body" v-if="errors.length == 0">
      <div
        class="result-container rejected"
        :class="{ verified: result.id == verifiedResultId }"
        v-for="(result, resultIndex) in unverifiedResults"
        :key="resultIndex"
      >
        <div class="main">
          <div class="left">
            <div class="first-name">
              {{ result.playerConnection.player.user.firstName }}
            </div>
            <div class="last-name">
              {{ result.playerConnection.player.user.lastName }}
            </div>
            <div class="hole">Hole {{ holeDisplayName(result.hole) }}</div>
          </div>
          <div class="right">
            <div class="score">{{ result.score }}</div>
            <div class="stats">
              <div v-if="result.isCircleHit">Circle hit</div>
              <div v-if="result.isInsidePutt">Inside putt</div>
              <div v-if="result.isOutsidePutt">Outside putt</div>
              <div v-if="result.isOutOfBounds">Out of bounds</div>
            </div>
          </div>
        </div>
        <div class="actions" v-if="!rejectedResults.includes(result.id)">
          <img
            src="@/assets/img/verify-reject.svg"
            alt
            @click="rejectedResults.push(result.id)"
          />
          <img
            src="@/assets/img/verify-confirm.svg"
            alt
            @click="verifyScore(result)"
          />
        </div>
        <div
          class="rejected"
          v-if="rejectedResults.includes(result.id)"
          :key="0 + result.id"
        ></div>
        <div
          class="fetch-latest cursor"
          v-if="rejectedResults.includes(result.id)"
          :key="1 + result.id"
          @click="fetchScores()"
        >
          Fetch latest score
        </div>
      </div>
      <div
        class="no-new-scores"
        v-if="usersOwnResults.length == 0 && unverifiedResults.length == 0"
      >
        <img src="@/assets/img/thumbs-up-white.svg" alt />
        No new scores to verify
        <div class="refresh-scores cursor" @click="fetchScores()">
          Fetch latest scores
        </div>
      </div>
      <div
        class="verify-own-scores"
        v-if="usersOwnResults.length > 0 && unverifiedResults.length == 0"
      >
        <img src="@/assets/img/unchecked-white.svg" alt />
        <span>Easy there.</span> You cannot verify scores that you entered
        yourself.
        <div class="refresh-scores ." @click="fetchScores()">
          Fetch latest scores
        </div>
      </div>
    </div>

    <div class="errors">
      <div class="hash" v-if="errors[0] == 'hash'">
        <img src="@/assets/img/thumbs-down.svg" alt />
        The score has been changed, check with the scorekeeper and try again!
        <div class="refresh-scores cursor" @click="fetchScores()">
          Fetch latest scores
        </div>
      </div>
      <div class="your-own-result" v-if="errors[0] == 'own'">
        <img src="@/assets/img/unchecked-white.svg" alt />
        You cannot verify your own results!
        <div class="refresh-scores cursor" @click="fetchScores()">
          Fetch latest scores
        </div>
      </div>
    </div>

    <transition name="slide-in-left" mode="out-in">
      <TheVerifierMenu
        v-if="showMenu"
        @closeMenu="toggleMenu"
        @refresh="refreshVerifier()"
      />
    </transition>
    <transition name="fade" mode="out-in">
      <div class="dim" v-if="showMenu"></div>
    </transition>
  </div>
</template>

<script>
import TheVerifierMenu from "@/components/scorecard/TheVerifierMenu";

export default {
  name: "TheVerifier",
  components: {
    TheVerifierMenu,
  },
  data() {
    return {
      showMenu: false,
      loading: true,
      groupData: {},
      rejectedResults: [],
      errors: [],
      verifiedResultId: "",
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    usersOwnResults() {
      var results = [];

      for (var i = 0; i < this.groupData.playerConnections.length; i++) {
        let playerResult = this.groupData.results.filter(
          (result) =>
            result.playerConnection.player.userId ==
              this.groupData.playerConnections[i].player.userId &&
            result.updatedByUserId == this.userInfo.sessionInfo.userId
        );

        for (var r = 0; r < playerResult.length; r++) {
          results.push(playerResult[r]);
        }
      }
      return results;
    },
    unverifiedResults() {
      var results = [];

      for (var i = 0; i < this.groupData.playerConnections.length; i++) {
        let playerResult = this.groupData.results.filter(
          (result) =>
            result.playerConnection.player.userId ==
              this.groupData.playerConnections[i].player.userId &&
            result.updatedByUserId != this.userInfo.sessionInfo.userId
        );

        for (var r = 0; r < playerResult.length; r++) {
          results.push(playerResult[r]);
        }
      }

      return results;
    },
  },
  watch: {},
  mounted() {
    this.fetchScores();
  },
  methods: {
    holeDisplayName(hole) {
      if (hole.name != null && hole.name != "") {
        return hole.name;
      } else {
        return hole.number;
      }
    },
    verifyScore(playerResult) {
      this.$store.dispatch("authCheck");

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            UpdateVerifyResult(resultId:"${playerResult.id}",isVerified:true,resultHash:"${playerResult.hash}",asRole:USER){id}
          }
          `,
        },
      })
        .then((result) => {
          if (result.data.data.UpdateVerifyResult == null) {
            if (result.data.errors[0].errorCode == "INVALID_RESULT_HASH") {
              this.errors.push("hash");
            }
          } else {
            this.verifiedResultId = playerResult.id;

            setTimeout(() => {
              var resultId = playerResult.id;
              var pos = this.groupData.results
                .map(function (e) {
                  return e.id;
                })
                .indexOf(resultId);
              this.groupData.results.splice(pos, 1);
            }, 750);
          }
        })
        .catch(() => {});
    },
    fetchScores() {
      this.rejectedResults = [];
      this.errors = [];

      this.$store.dispatch("authCheck");

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            group(groupId:"${this.$router.currentRoute.params.groupId}"){
              pool{
                id
                status
                round{
                  event{
                    id
                  }
                }
              }
              playerConnections{
                player{
                  userId
                  user{
                    id
                    firstName
                    lastName
                  }
                }
              }
              results(isVerified:false){
                id
                score
                isCircleHit
                isOutsidePutt
                isInsidePutt
                isOutOfBounds
                createdByUserId
                updatedByUserId
                hash
                isVerified
                playerConnection{
                  player{
                    userId
                    user{
                      id
                      firstName
                      lastName
                    }
                  }
                }
                hole{
                  id
                  name
                  number
                  par
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          this.groupData = result.data.data.group;
          this.loading = false;

          if (result.data.data.group.pool.status == "COMPLETED") {
            this.$router.push({ name: "scorecard-picker" });
          }
        })
        .catch(() => {});
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    refreshVerifier() {},
  },
};
</script>
<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
.your-own-result {
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15vh 15px 0 15px;
  text-align: center;
  font-size: 32px;
  color: white;
  @include Gilroy-Bold;

  span {
    color: $sky;
  }

  img {
    height: 64px;
    margin-bottom: 20px;
  }
}
.hash {
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15vh 15px 0 15px;
  text-align: center;
  font-size: 32px;
  color: white;
  @include Gilroy-Bold;

  span {
    color: $sky;
  }

  img {
    height: 64px;
    margin-bottom: 20px;
  }
}
.verify-own-scores {
  height: calc(100vh - 44px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10vh 15px 0 15px;
  text-align: center;
  font-size: 32px;
  color: white;
  @include Gilroy-Bold;

  span {
    color: $sky;
  }

  img {
    height: 64px;
    margin-bottom: 20px;
  }
}
.no-new-scores {
  height: calc(100vh - 44px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10vh 65px 0 65px;
  text-align: center;
  font-size: 32px;
  color: white;
  @include Gilroy-Bold;

  img {
    height: 64px;
    margin-bottom: 20px;
  }
}

.refresh-scores {
  height: 60px;
  display: flex;
  width: 300px;
  align-items: center;
  margin-bottom: 60px;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid $sky;
  font-size: 22px;
  margin-top: 100px;
  @include Gilroy-Bold;
  color: $sky;
}

.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 1;
  transform: translateY(-250px);
  margin-top: -250px;
}
.list-complete-leave-active {
  position: absolute;
}
.body {
  width: 100%;
  padding-top: 44px;
  overflow: hidden;

  .result-container {
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid lighten($midnight, 10%);
    padding: 20px 20px 30px 20px;
    @include Gilroy-Bold;
    color: white;
    max-height: 300px;
    overflow: hidden;
    position: relative;
    background-color: $midnight;

    &.verified {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
      transition: all ease-in-out 0.75s;
    }

    &:nth-child(2) {
      z-index: 49;
    }
    &:nth-child(3) {
      z-index: 48;
    }
    &:nth-child(4) {
      z-index: 47;
    }
    &:nth-child(5) {
      z-index: 46;
    }
    &:nth-child(6) {
      z-index: 45;
    }
    &:nth-child(7) {
      z-index: 44;
    }
    &:nth-child(8) {
      z-index: 43;
    }

    .rejected {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $watermelon;
      opacity: 0.9;
    }
    .fetch-latest {
      z-index: 3;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      background-color: white;
      border-radius: 6px;
      height: 60px;
      font-size: 28px;
      color: $midnight;
      width: 300px;
      margin: 0 auto;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .main {
      display: flex;
      margin-bottom: 20px;

      .left {
        width: 60%;

        .first-name {
          @include Gilroy-Regular;
          font-size: 22px;
          line-height: 22px;
        }
        .last-name {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 10px;
        }
        .hole {
          color: $albatross;

          span {
            display: block;
            color: $elephant;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        width: 40%;
        text-align: right;

        span {
          font-size: 30px;
        }

        .score {
          font-size: 70px;
          @include Gilroy-Bold;
          line-height: 60px;
        }
        .stats {
          margin-top: 15px;
          font-size: 18px;
          color: $elephant;
          line-height: 1em;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;

      img {
        cursor: pointer;
      }
    }
  }
}
.dim {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.verifier-container {
  min-height: 100vh;
  background-color: $midnight;
  position: relative;
}
.header {
  padding-left: 10px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px darken($midnight, 5%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  @include Gilroy-Bold;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  letter-spacing: 1.1px;
  z-index: 999;
  background-color: $midnight;

  #nav-icon3 {
    position: absolute;
    left: 10px;
    top: 12px;
  }
}
.slide-in-left-enter {
  left: -100vw;
  top: -1px;
}

.slide-in-left-enter-to {
  top: -1px;
  left: 0px;
  width: 100vw;
  opacity: 1;
  top: -1px;
}
.slide-in-left-leave-active {
  left: -100vw;
  top: -1px;
}

#nav-icon3 {
  width: 24px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 8px;
}

#nav-icon3 span:nth-child(4) {
  top: 16px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
</style>
