<template>
  <div
    class="mobile-menu dark locked"
    @click.self="close"
    v-hammer:swipe="closeMenu"
  >
    <div class="menu-items">
      <div class="item" @click="goTo('marker')">Switch to Scorecard</div>
      <div class="item" @click="goTo('live')">Live scores</div>
      <div class="item" @click="goTo('results')">Results</div>
      <div class="item" @click="$emit('refresh')">Refresh</div>
      <div
        class="item"
        v-if="userScorecards.length > 1"
        @click="goTo('picker')"
      >
        Choose other scorecard
      </div>
      <div class="item" @click="goTo('profile')">Your profile</div>
      <div class="item" @click="$store.dispatch('logout')">Log out</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheVerifierMenu",
  data() {
    return {
      adminView: false,
    };
  },
  props: [],
  computed: {
    userScorecards() {
      return this.$store.getters.userScorecards;
    },
  },
  methods: {
    resignMarshall() {
      this.$emit("resignMarshall");
    },
    goTo(value) {
      if (value == "picker") {
        this.$router.push({ name: "scorecard-picker" });
      } else if (value == "results") {
        this.$router.push({
          name: "public-event-results",
          params: { eventId: this.$parent.groupData.pool.round.event.id },
        });
      } else if (value == "live") {
        let poolId = this.$parent.groupData.pool.id;
        let eventId = this.$parent.groupData.pool.round.event.id;

        this.$router.push({
          name: "public-event-pool-results",
          params: { poolId: poolId, eventId: eventId },
        });
      } else if (value == "marker") {
        this.$router.push({ name: "marker" });
      } else if (value == "profile") {
        this.$router.push({ name: "profile" });
      }
    },
    close() {
      this.$emit("closeMenu");
    },
    closeMenu(event) {
      if (event.direction == 2) {
        this.$emit("closeMenu");
      }
    },
    clickEvent(event) {
      if (event.action == "close") {
        this.$emit("closeModal");
      }
      if (event.action == "execute") {
        this.$emit("execute");
      }
    },
  },
  beforeMount() {
    if (this.$router.currentRoute.path.includes("/manage/")) {
      this.adminView = true;
    }
  },
  beforeCreate() {
    document.body.classList.add("modal-open");
    window.scrollTo(0, top);
  },
  destroyed() {
    document.body.classList.remove("modal-open");
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  position: absolute;
  touch-action: none;
  top: -1px;
  left: 0;
  z-index: 51;
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: all ease 0.5s;
  max-height: 100vh;
  min-height: 100vh;
  box-shadow: none;

  &.locked {
    top: 44px;
  }

  &.dark {
    color: white;
    background: rgba(0, 0, 0, 0);
  }
}

.menu-items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 80%;
  background-color: $midnight;
  min-height: 100vh;
  box-shadow: 1px 3px 10px darken($midnight, 5%);
  margin-top: 0;

  .item {
    display: flex;
    align-items: center;

    border-bottom: 1px solid lighten($midnight, 10%);
    padding: 15px 10px 15px 10px;
    width: 100%;
    img {
      margin-right: 10px;
    }
  }
}
button {
  &.sky {
    background-color: $sky;
    color: $midnight;
    font-size: 18px;

    &.reversed {
      color: $sky;
      background-color: transparent;
      border: 0px solid white;
    }
  }
}
a {
  color: white;
  text-decoration: none;
  &:active,
  &:hover {
    color: white;
    text-decoration: none;
  }
}
</style>
